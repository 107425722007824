@charset "UTF-8";
/*Специализация*/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@font-face {
  font-family: 'Geometria';
  src: url("../fonts/Geometria.eot");
  src: local("Geometria"), url("../fonts/Geometria.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria.woff") format("woff"), url("../fonts/Geometria.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

@font-face {
  font-family: 'Geometria';
  src: url("../fonts/Geometria-Medium.eot");
  src: local("Geometria Medium"), local("Geometria-Medium"), url("../fonts/Geometria-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria-Medium.woff") format("woff"), url("../fonts/Geometria-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: block; }

@font-face {
  font-family: 'Geometria';
  src: url("../fonts/Geometria-Bold.eot");
  src: local("Geometria Bold"), local("Geometria-Bold"), url("../fonts/Geometria-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria-Bold.woff") format("woff"), url("../fonts/Geometria-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: block; }

@font-face {
  font-family: 'Geometria';
  src: url("../fonts/Geometria-Light.eot");
  src: local("Geometria Light"), local("Geometria-Light"), url("../fonts/Geometria-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria-Light.woff") format("woff"), url("../fonts/Geometria-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: block; }

@font-face {
  font-family: 'Museo Sans Cyrl';
  src: url("../fonts/MuseoSansCyrl-700.eot");
  src: local("MuseoSansCyrl-700"), url("../fonts/MuseoSansCyrl-700.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoSansCyrl-700.woff") format("woff"), url("../fonts/MuseoSansCyrl-700.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: block; }

@font-face {
  font-family: 'Museo Sans Cyrl';
  src: url("../fonts/MuseoSansCyrl-300.eot");
  src: local("MuseoSansCyrl-300"), url("../fonts/MuseoSansCyrl-300.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoSansCyrl-300.woff") format("woff"), url("../fonts/MuseoSansCyrl-300.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

@font-face {
  font-family: 'Museo Sans Cyrl';
  src: url("../fonts/MuseoSansCyrl-900.eot");
  src: local("MuseoSansCyrl-900"), url("../fonts/MuseoSansCyrl-900.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoSansCyrl-900.woff") format("woff"), url("../fonts/MuseoSansCyrl-900.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: block; }

body {
  font-family: 'Geometria';
  font-size: 16px;
  color: #000;
  line-height: normal;
  background: #fff url(../img/body.png) no-repeat center;
  background-size: cover; }

* {
  box-sizing: border-box; }

button:focus,
input:focus {
  outline: 0; }

button {
  cursor: pointer;
  max-width: 100%; }

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0; }

ul {
  list-style: none; }

a {
  color: inherit;
  text-decoration: none; }

body, html {
  overflow-x: hidden; }

img {
  max-width: 100%; }

h2 {
  font-size: 32px;
  font-family: 'Museo Sans Cyrl';
  text-align: center;
  font-weight: 700;
  text-transform: uppercase; }
  h2 strong {
    color: #1094d9; }

a:hover {
  color: inherit;
  text-decoration: none; }

.container {
  box-sizing: content-box; }

.cap {
  display: flex;
  align-items: flex-start;
  padding-top: 28px; }

.header {
  background: url(../img/firts.png) no-repeat center/cover; }

.logo {
  display: flex;
  align-items: flex-start;
  max-width: 315px;
  font-family: 'Open Sans'; }
  .logo img {
    min-width: 67px;
    width: 67px;
    display: block; }

.logo__name {
  font-size: 31px;
  font-weight: 700;
  color: #000e1f;
  text-transform: uppercase; }

.logo__sub-name {
  font-size: 12px;
  color: #8a8a8a;
  line-height: 15px;
  display: block; }

.logo__text {
  margin-left: 16px; }

.call {
  display: flex;
  align-items: center;
  margin-left: 87px;
  margin-top: 11px;
  font-family: 'Museo Sans Cyrl';
  font-weight: 700; }

.call__phone {
  display: block;
  font-size: 22px;
  color: #095cb1;
  font-weight: 700;
  padding-left: 29px;
  background: url(../img/call-answer.svg) no-repeat left center;
  background-size: 19px; }

.call__button {
  width: 206px;
  height: 33px;
  border-radius: 3px;
  background-color: #76a5c3;
  border: none;
  font-size: 11px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Museo Sans Cyrl';
  font-weight: 700;
  margin-left: 19px; }

.first__title {
  font-size: 46px;
  line-height: 54px;
  color: #1d1d1d;
  font-family: 'Museo Sans Cyrl';
  font-weight: 900;
  text-transform: uppercase; }

.first__label {
  font-size: 24px;
  font-family: 'Museo Sans Cyrl';
  font-weight: 900;
  text-transform: uppercase;
  color: #fff;
  padding: 10px 33px;
  background: #2c8be4;
  border-radius: 6px;
  display: inline-block;
  margin-top: 20px; }

.first__offer {
  position: relative;
  max-width: 787px;
  margin-top: 45px; }
  .first__offer:after {
    content: '';
    position: absolute;
    bottom: -18px;
    z-index: 1;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    width: 742px;
    height: 164px;
    background: url(../img/bottom.png) no-repeat center bottom;
    background-size: contain; }

.first__offer_wrap {
  position: relative;
  padding: 22px 0 32px;
  z-index: 2;
  border-radius: 9px;
  background-color: white;
  box-shadow: 0px 10px 13px 0px rgba(10, 19, 23, 0.11); }

.first__offer_title {
  font-size: 24px;
  font-weight: 700;
  color: #005fa9;
  text-align: center; }

.first__offer_label {
  color: #7b7b7c;
  font-family: 'Museo Sans Cyrl';
  text-align: center;
  margin: 6px 0 30px; }

.first__offer_wrapper {
  display: flex;
  align-items: center;
  justify-content: center; }

.first__offer_bonus {
  position: relative;
  width: 359px;
  background-color: #ffeaea;
  font-size: 14px;
  font-weight: 500;
  padding: 11px 10px 15px 103px;
  color: #2f2f2f;
  margin-right: 15px; }

.first__offer_prize {
  position: absolute;
  left: -16px;
  top: -17px; }

.button {
  position: relative;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #313131;
  border: none;
  padding: 0; }
  .button:before {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: #f5af14;
    box-shadow: 0px 4px 21px 0px rgba(233, 183, 108, 0.4);
    position: absolute;
    left: 0;
    bottom: -3px;
    z-index: 0; }
  .button span {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-image: linear-gradient(0deg, #ffc333 0%, #ffdf9a 100%);
    background-image: -moz-linear-gradient(0deg, #ffc333 0%, #ffdf9a 100%);
    background-image: -webkit-linear-gradient(0deg, #ffc333 0%, #ffdf9a 100%);
    background-image: -ms-linear-gradient(0deg, #ffc333 0%, #ffdf9a 100%);
    box-shadow: inset 0px 1px 3px 0px rgba(221, 165, 85, 0.07);
    width: 313px;
    height: 65px;
    z-index: 2; }

.first__merits_item {
  display: flex;
  align-items: center; }

.first__merits_icon-box {
  width: 117px;
  height: 117px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../img/elipse.png) no-repeat; }

.first__merits_icon {
  background: #2C8BE4;
  border-radius: 50%;
  width: 53px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .first__merits_icon img {
    max-width: 23px; }

.first__merits_text {
  font-weight: 500;
  color: #272727;
  line-height: normal;
  margin-left: 16px; }

.first__merits {
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 58px; }

.first {
  padding-top: 125px; }

.merits__img {
  height: 146px;
  display: flex;
  align-items: center;
  justify-content: center; }

.merits__box {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 78px; }

.merits__name {
  margin: 22px 0 13px;
  font-weight: 700;
  color: #060606; }

.merits__text {
  font-size: 14px;
  font-family: 'Museo Sans Cyrl';
  color: #111111; }

.merits {
  padding: 141px 0 135px; }

.order__box {
  position: relative;
  padding: 56px 67px 50px;
  border-radius: 9px;
  background: #fff;
  box-shadow: 0px 0px 47.6px 20.4px rgba(10, 19, 23, 0.06); }
  .order__box:after {
    content: '';
    position: absolute;
    bottom: -30px;
    z-index: -1;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    width: 1074px;
    height: 164px;
    background: url(../img/bottom.png) no-repeat center bottom; }

.order__title {
  font-size: 38px;
  text-align: left; }

.order__label {
  font-weight: 300;
  margin: 17px 0 30px; }

.input {
  width: 243px;
  height: 48px;
  background-color: #f5f8fc;
  border: 1px solid #cedaf0;
  padding-left: 44px;
  font-size: 14px;
  background: url(../img/user.svg) no-repeat 13px center;
  background-size: 17px 20px; }

.input--phone {
  background-image: url(../img/phone.svg);
  margin-left: 10px; }

.order__wrapper {
  display: flex;
  align-items: center;
  margin-top: 17px; }

.order__button span {
  width: 246px;
  height: 50px; }

.order__concent {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 300;
  color: #3d3d3d; }

.order__img {
  position: absolute;
  top: -27px;
  right: 0;
  z-index: 1; }

.order__content {
  position: relative;
  z-index: 2; }

.stages__item {
  text-align: center;
  margin-bottom: 110px; }

.stages__name {
  font-size: 18px;
  font-weight: 500;
  color: #060606; }

.stages__text {
  font-size: 16px;
  font-family: 'Museo Sans Cyrl';
  line-height: 1.551;
  letter-spacing: 0.5px;
  margin-top: 8px; }

.stages__img {
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center; }

.stages__box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 20px;
  margin-top: 57px;
  background: url(../img/stages.png) no-repeat center top; }

.stages__wrapper--2 {
  width: 100%; }
  .stages__wrapper--2 .stages__item {
    margin: -123px auto 0; }

.stages {
  padding-top: 145px; }

.quiz__item {
  min-height: 503px;
  border-radius: 9px;
  background-color: #fff;
  box-shadow: 0px 0px 77.7px 33.3px rgba(10, 19, 23, 0.07); }

.quiz__progress_text {
  font-size: 14px;
  color: #4f4f4f;
  text-align: center;
  margin-bottom: 41px; }

.quiz__left {
  width: 768px;
  padding-top: 29px;
  display: none; }

.quiz__left--1 {
  display: block; }
  .quiz__left--1 .quiz__button {
    margin-top: 50px; }

.quiz__left--3 .quiz__button {
  margin-top: 50px; }

.quiz__left--4 .quiz__button {
  margin-top: 108px; }

.quiz__progress_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 570px;
  height: 11px;
  background-color: #fafafa;
  box-shadow: inset 0px 1px 3px 0px rgba(2, 3, 4, 0.05); }

.quiz__progress_elipse {
  position: absolute;
  left: -23px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05), inset 0px 3px 2px 0px rgba(2, 3, 4, 0.05); }
  .quiz__progress_elipse:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffc73e; }

.quiz__progress_elipse--2 {
  left: auto;
  right: -23px; }

.quiz__progress_line {
  position: relative;
  width: 33.3%;
  height: 100%; }
  .quiz__progress_line.active {
    background: url(../img/line.png) no-repeat;
    background-size: cover; }

.quiz__quest {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  margin: 42px 0 29px; }

.checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 20px; }

.checkbox {
  margin-bottom: 12px; }

.checkbox__text {
  width: 285px;
  display: block;
  position: relative;
  padding: 16px 10px 17px 54px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  border: 1px dashed #dddddd;
  color: #292929; }
  .checkbox__text:after {
    content: '';
    position: absolute;
    left: 21px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
    width: 18px;
    height: 18px;
    background: url(../img/check.svg) no-repeat center;
    background-size: 10px;
    opacity: 0;
    transition: all 0.3s ease; }
  .checkbox__text:before {
    content: '';
    position: absolute;
    left: 21px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
    border-radius: 4px;
    background-color: white;
    box-shadow: inset 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
    width: 18px;
    height: 18px; }

.checkbox input:checked + .checkbox__text {
  background: #2D8CE5;
  color: #fff;
  font-weight: 500;
  border-color: transparent; }
  .checkbox input:checked + .checkbox__text:after {
    opacity: 1; }

.quiz__checks {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 592px;
  margin: 0 auto; }

.quiz__button {
  position: relative;
  width: 247px;
  height: 50px;
  cursor: pointer;
  margin: 25px auto 0; }
  .quiz__button span {
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-weight: 500;
    padding-right: 21px; }
  .quiz__button:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 35px;
    margin: auto;
    display: block;
    width: 9px;
    height: 9px;
    background: url(../img/right-arrow.svg) no-repeat center;
    background-size: contain;
    z-index: 3; }

.quiz__wrapper {
  position: relative; }

.quiz__right {
  position: absolute;
  right: 0;
  top: 0;
  width: 372px;
  height: 503px;
  background-color: #75b0ed;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px; }
  .quiz__right img {
    position: absolute;
    top: -25px;
    right: 0px;
    max-width: none; }

.quiz {
  padding: 97px 0 15px; }
  .quiz .order__wrapper {
    justify-content: center; }

.quiz__box {
  position: relative;
  margin-top: 51px; }
  .quiz__box:after {
    content: '';
    position: absolute;
    bottom: -30px;
    z-index: -1;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    width: 1074px;
    height: 164px;
    background: url(../img/bottom.png) no-repeat center bottom; }

.quiz__button {
  display: block; }

.consultation {
  background: url(../img/cons.png) no-repeat center;
  background-size: cover; }
  .consultation .order__box {
    max-width: 632px;
    margin-right: 42px;
    margin-left: auto; }
    .consultation .order__box:after {
      width: 95%; }
  .consultation .container {
    position: relative;
    padding: 203px 15px 140px; }

.consultation__img {
  position: absolute;
  bottom: 0;
  left: 45px; }

.acc-body {
  display: none; }

.questions__title {
  text-align: left; }

.acc-head {
  position: relative;
  border-radius: 9px;
  background-color: white;
  box-shadow: 0px 0px 20.3px 8.7px rgba(10, 19, 23, 0.05);
  font-weight: 500;
  color: #222222;
  padding: 27px 25px;
  cursor: pointer; }
  .acc-head:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 32px;
    margin: auto;
    display: block;
    width: 10px;
    height: 10px;
    background: url(../img/right-arrow.svg) no-repeat center;
    background-size: 10px;
    transform: rotate(90deg);
    transition: all 0.3s ease; }
  .acc-head.active:after {
    transform: rotate(-90deg); }

.acc-item {
  max-width: 705px;
  border-radius: 9px;
  background-color: white;
  box-shadow: 0px 0px 14.88px 1.12px rgba(10, 19, 23, 0.03);
  margin-bottom: 10px; }

.acc-body {
  font-weight: 300;
  padding: 15px 25px 28px; }
  .acc-body p {
    margin-bottom: 11px; }

.accordeon {
  margin-top: 36px; }

.questions .container {
  padding-top: 76px;
  padding-bottom: 56px;
  background: url(../img/quest.png) no-repeat right bottom; }

.questions__img {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1; }

.contacts {
  position: relative;
  padding: 79px 0 59px; }

.map {
  background: #ccc;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; }

.contacts__box {
  position: relative;
  padding: 40px 34px;
  position: relative;
  z-index: 2;
  width: 390px;
  border-radius: 9px;
  background-color: white;
  box-shadow: 0px 0px 37.8px 16.2px rgba(10, 19, 23, 0.08); }
  .contacts__box:after {
    content: '';
    position: absolute;
    bottom: -10px;
    z-index: 1;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    width: 95%;
    height: 54px;
    background: url(../img/bottom.png) no-repeat center bottom;
    background-size: contain; }

.contacts__title {
  text-align: left;
  font-size: 23px; }

.contacts__caption {
  font-weight: 500;
  color: #1a1a1a;
  position: relative; }
  .contacts__caption:before {
    content: '';
    position: absolute;
    left: -42px;
    top: 10px;
    display: block;
    background: #FFC73E;
    width: 22px;
    height: 4px; }

.contacts__text {
  font-weight: 300;
  color: #1a1a1a;
  margin-top: 4px; }

.contacts__item {
  padding-left: 42px;
  margin-top: 15px; }

.contacts__wrapper {
  margin-top: 24px; }

.footer__box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 0; }

.modal-dialog {
  max-width: 394px; }

.modal__title {
  font-size: 19px;
  font-family: 'Museo Sans Cyrl';
  font-weight: 700;
  color: #3a3e42;
  margin-bottom: 16px; }

.order__modal {
  padding: 45px 35px 37px; }

.form__label {
  font-size: 15px;
  color: #8D9199;
  font-family: 'Open Sans';
  margin-bottom: 12px; }

.modal__input {
  width: 315px;
  height: 50px;
  margin: 0;
  font-family: 'Open Sans'; }

.form__group {
  margin-top: 16px; }

.modal__button {
  width: 319px;
  height: 50px;
  margin-top: 15px; }
  .modal__button span {
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-weight: 500; }

.modal__concent {
  font-size: 11px;
  color: #3d3d3d;
  font-family: 'Open Sans';
  margin-top: 20px; }

.close {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 15px;
  height: 15px;
  background: url(../img/exit.svg) no-repeat; }

@media screen and (max-width: 1199px) {
  .first__merits_icon-box {
    width: 60px;
    height: 60px;
    background-size: contain; }
  .first__merits_icon {
    width: 40px;
    height: 40px; }
  .merits__text br {
    display: none; }
  .merits__item {
    width: 23%; }
  .order__img {
    width: 600px;
    top: auto;
    bottom: 0; }
  .quiz__left {
    width: 640px; }
  .quiz__right {
    width: 320px; }
    .quiz__right img {
      max-width: 110%;
      top: -20px; }
  .consultation__img {
    width: 300px; } }

@media screen and (max-width: 991px) {
  .call {
    margin: 0; }
  .cap {
    align-items: center;
    justify-content: space-between; }
  .first__title {
    font-size: 35px; }
  .first {
    padding-top: 60px; }
  .first__offer::after {
    width: 95%; }
  .first__merits {
    flex-wrap: wrap; }
  .first__merits_item {
    width: 50%;
    margin-bottom: 15px;
    justify-content: center; }
  .first__merits_text {
    width: 138px; }
  .merits__box {
    flex-wrap: wrap; }
  .merits__item {
    width: 48%;
    margin-bottom: 40px; }
  .merits {
    padding: 100px 0 60px 0; }
  .order__img {
    display: none; }
  .order__box::after {
    display: none; }
  .stages__box {
    background: transparent; }
  .quiz__right {
    position: relative;
    width: 100%;
    height: 300px;
    margin-top: 30px;
    border-bottom-left-radius: 9px;
    border-top-right-radius: 0px; }
    .quiz__right img {
      max-height: 100%;
      left: 0;
      display: block;
      margin: auto; }
  .quiz__left {
    width: 100%; }
  .consultation__img {
    display: none; }
  .consultation .container {
    padding-top: 60px;
    padding-bottom: 60px; }
  .questions .container {
    background: transparent; } }

@media screen and (max-width: 767px) {
  .container {
    width: 320px; }
  .cap {
    flex-direction: column; }
  .logo__name {
    font-size: 22px; }
  .logo__sub-name {
    font-size: 10px;
    max-width: 190px; }
  .logo {
    max-width: 100%;
    align-items: center; }
  .call {
    flex-direction: column;
    align-items: center;
    margin-top: 15px; }
  .call__phone {
    margin-bottom: 15px; }
  .cap {
    max-width: 100%;
    padding-top: 15px; }
  .container {
    box-sizing: border-box; }
  .first__title {
    font-size: 28px;
    text-align: center;
    line-height: 40px; }
  .first__label {
    padding: 10px;
    width: 100%;
    font-size: 18px;
    display: block;
    text-align: center; }
  .first {
    padding-top: 30px; }
  .first__offer_wrapper {
    display: block; }
  .first__offer_bonus {
    width: 100%;
    padding-left: 64px;
    font-size: 12px;
    margin-bottom: 15px; }
  .first__offer_prize {
    width: 80px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block; }
  .button {
    width: 100%;
    height: 45px;
    display: block; }
    .button span {
      width: 100%;
      height: 100%; }
  .first__offer_wrap {
    padding: 20px 10px; }
  .first__offer_title {
    font-size: 18px; }
    .first__offer_title br {
      display: none; }
  body {
    font-size: 14px; }
  .header {
    background: transparent; }
  .first__merits_item {
    width: 100%; }
  .first__merits {
    position: static;
    margin-top: 20px; }
  h2 {
    font-size: 20px; }
    h2 br {
      display: none; }
  .merits {
    padding: 40px 0; }
  .merits__item {
    width: 100%; }
  .merits__box {
    margin-top: 30px; }
  .order__box {
    padding: 20px 10px;
    text-align: center; }
  .order__wrapper {
    display: block; }
  .input {
    width: 100%; }
  .input--phone {
    margin: 10px 0 0; }
  .order__concent {
    margin: 10px 0 0; }
  .order__title {
    font-size: 20px;
    text-align: center; }
  .order__label {
    margin: 10px 0 20px; }
    .order__label br {
      display: none; }
  .stages__item {
    width: 100%;
    margin-bottom: 30px; }
  .stages__wrapper {
    width: 100%; }
  .stages__box {
    margin-top: 20px; }
  .stages {
    padding-top: 40px; }
  .stages__wrapper--2 .stages__item {
    margin: 0; }
  .quiz__progress_box {
    width: 250px; }
  .quiz__progress_elipse {
    left: -15px; }
  .quiz__progress_elipse--2 {
    left: auto;
    right: -15px; }
  .checkbox__text {
    width: 100%; }
  .checkbox {
    width: 100%; }
  .quiz__checks {
    width: 100%; }
  .quiz__left {
    padding: 20px 10px 0; }
  .quiz__quest {
    font-size: 18px; }
  .quiz__box {
    margin-top: 30px; }
  .quiz {
    padding-top: 40px; }
  body {
    background: #fff; }
  .stages__wrapper--1 {
    order: 1; }
  .stages__wrapper--2 {
    order: 2; }
  .stages__wrapper--3 {
    order: 3;
    display: flex;
    flex-direction: column; }
    .stages__wrapper--3 :first-child {
      order: 2; }
    .stages__wrapper--3 :nth-child(2) {
      order: 1; }
  .consultation .order__box {
    margin-right: 0; }
  .consultation {
    background: #fff; }
  .questions__title {
    text-align: center; }
  .questions .container {
    padding-top: 0;
    padding-bottom: 0; }
  .acc-head {
    padding: 15px 30px 15px 10px; }
  .acc-head::after {
    right: 10px; }
  .map {
    position: static;
    width: 100%;
    height: 250px; }
  .contacts__box {
    padding: 20px 10px;
    width: 100%;
    margin-bottom: 30px; }
  .contacts {
    padding: 40px 0 0; }
  .contacts__box::after {
    display: none; }
  .footer__box {
    flex-direction: column;
    align-items: center; }
  .order__modal {
    padding: 40px 10px; }
  .modal__title {
    text-align: center;
    font-size: 17px; }
    .modal__title br {
      display: none; }
  .quiz__quest br {
    display: none; } }
